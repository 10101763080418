import React from "react";
import {
  useMenuState,
  Menu as BaseMenu,
  MenuItem as BaseMenuItem,
  MenuButton,
  MenuStateReturn,
} from "reakit/Menu";
import { Typography } from "../typography";
import rightArrowIcon from "assets/images/chevronRight.svg";
import styles from "./Menu.module.css";
import cx from "classnames";
import { MenuItem, MenuItemType } from "./types";
import { highlightTextFragment } from "utilities/highlightTextFragment";
import { noop } from "utilities";
import { Line } from "../line";
import { IconRenderer } from "../shared/IconRenderer";
import { Tag } from "../tag";

interface Props {
  className?: string;
  disclosure: JSX.Element;
  menuItems: MenuItem[];
}

const DEFAULT_ICON_SIZE = 16 as const;

export const Menu = React.forwardRef(
  ({ className, disclosure, menuItems, ...props }: Props, ref) => {
    const menu = useMenuState();

    return (
      <>
        <MenuButton ref={ref} {...menu} {...props} {...disclosure.props}>
          {disclosureProps => React.cloneElement(disclosure, disclosureProps)}
        </MenuButton>
        <MenuList className={className} menuItems={menuItems} menuState={menu} />
      </>
    );
  },
);

export const MenuList = ({
  menuItems,
  menuState,
  hideOnClickOutside = true,
  className,
  searchValue,
  showNoMenuItemsInfo = true,
}: {
  menuItems: MenuItem[];
  menuState: MenuStateReturn;
  hideOnClickOutside?: boolean;
  className?: string;
  searchValue?: string;
  showNoMenuItemsInfo?: boolean;
}) => {
  if (!menuItems.length) {
    if (!showNoMenuItemsInfo) {
      return <BaseMenu {...menuState}></BaseMenu>;
    }
    return (
      <BaseMenu
        {...menuState}
        aria-label="base-menu"
        hideOnClickOutside={hideOnClickOutside}
        className={cx(styles.dropMenu, className)}
      >
        <div className={styles.dropDownOption}>
          <Typography fontSize="14" fontWeight="700">
            Brak wyników
          </Typography>
        </div>
      </BaseMenu>
    );
  }

  return (
    <BaseMenu
      {...menuState}
      aria-label="base-menu"
      hideOnClickOutside={hideOnClickOutside}
      className={cx(styles.dropMenu, className)}
    >
      {menuItems.map((menuItem, index) => {
        const itemComponent = getMenuComponent(menuItem, searchValue);
        return (
          <BaseMenuItem {...menuState} {...itemComponent.props} key={index}>
            {itemProps => React.cloneElement(itemComponent, itemProps)}
          </BaseMenuItem>
        );
      })}
    </BaseMenu>
  );
};

const getMenuComponent = (menuItem: MenuItem, searchValue?: string) => {
  switch (menuItem.type) {
    case MenuItemType.TAG: {
      const Icon = menuItem.icon;
      return (
        <div
          className={cx(styles.dropDownOption, menuItem?.options?.className, {
            [styles.disabled]: menuItem.options && menuItem.options.disabled,
          })}
          onClick={
            !menuItem.options?.disabled
              ? () => {
                  menuItem.onClick();
                }
              : noop
          }
          onKeyDown={event => {
            switch (event.key) {
              case "Enter":
                event.preventDefault();
                menuItem.onClick();
                return;
            }
          }}
        >
          {Icon && (
            <IconRenderer
              color={menuItem?.options?.color || "neutralBlack100"}
              icon={Icon}
              size={DEFAULT_ICON_SIZE}
            />
          )}
          {menuItem.backgroundColor && menuItem.textColor ? (
            <Tag.WithCustomColor
              backgroundColor={menuItem.backgroundColor}
              label={menuItem.text}
              textColor={menuItem.textColor}
            />
          ) : (
            <Tag label={menuItem.text} variant="quaternary" />
          )}
          {menuItem?.options?.buttonEnd}
        </div>
      );
    }
    case MenuItemType.AVATAR:
    case MenuItemType.TEXT:
      const Icon = menuItem.options?.iconStart;
      return (
        <div
          className={cx(styles.dropDownOption, menuItem?.options?.className, {
            [styles.disabled]: menuItem.options && menuItem.options.disabled,
          })}
          onClick={!menuItem.options?.disabled ? menuItem.onClick : noop}
          onKeyDown={event => {
            switch (event.key) {
              case "Enter":
                event.preventDefault();
                menuItem.onClick();
                return;
            }
          }}
        >
          {menuItem.type === MenuItemType.AVATAR ? (
            <img alt="awatar" src={menuItem.avatar} style={{ height: "16px", width: "16px" }} />
          ) : Icon && typeof Icon === "function" ? (
            <Icon color={menuItem?.options?.color} size="16" />
          ) : null}
          <div className="d-flex flex-column gap-1">
            <Typography
              color={menuItem.options?.color}
              fontSize={menuItem.options?.typographyProps?.fontSize || "14"}
              fontWeight={menuItem.options?.typographyProps?.fontWeight || "700"}
            >
              {highlightTextFragment(searchValue, menuItem.text, menuItem.text)}
            </Typography>
            {menuItem.helperText && (
              <Typography className="italic" color="neutralBlack48" fontSize="10" fontWeight="600">
                {menuItem.helperText}
              </Typography>
            )}
          </div>

          {menuItem?.options?.buttonEnd}
        </div>
      );
    case MenuItemType.DIVIDER:
      return <Line className="my-1" color="neutralBlack16" lineStyle="solid" />;
    case MenuItemType.ICON:
      return (
        <div
          className={cx(styles.dropDownOption, menuItem?.options?.className, {
            [styles.disabled]: menuItem.options && menuItem.options.disabled,
          })}
          onClick={!menuItem.options?.disabled ? menuItem.onClick : noop}
          onKeyDown={event => {
            switch (event.key) {
              case "Enter":
                event.preventDefault();
                menuItem.onClick();
                return;
            }
          }}
        >
          <IconRenderer
            color={menuItem?.options?.color ?? "neutralBlack100"}
            icon={menuItem.icon}
            size={DEFAULT_ICON_SIZE}
          />
          <div className="d-flex flex-column gap-1">
            <Typography
              color={menuItem.options?.color}
              fontSize={menuItem.options?.typographyProps?.fontSize || "14"}
              fontWeight={menuItem.options?.typographyProps?.fontWeight || "700"}
            >
              {highlightTextFragment(searchValue, menuItem.text, menuItem.text)}
            </Typography>
            {menuItem.helperText && (
              <Typography className="italic" color="neutralBlack48" fontSize="10" fontWeight="600">
                {menuItem.helperText}
              </Typography>
            )}
          </div>
          {menuItem?.options?.buttonEnd}
        </div>
      );
    case MenuItemType.SECTION:
      return (
        <div className={styles.section}>
          {menuItem.title && (
            <div className={styles.header}>
              <Typography
                className={styles.headerTypography}
                color="neutralBlack48"
                fontSize="12"
                fontWeight="700"
              >
                {menuItem.title}
              </Typography>
            </div>
          )}
          <Menu
            disclosure={
              <div className={cx(styles.dropDownOption, styles.sectionDisclosure)}>
                <Typography fontSize="14" fontWeight="700">
                  {menuItem.text}
                </Typography>
                <img alt="submenu" src={rightArrowIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            }
            menuItems={menuItem.menuItems}
          />
        </div>
      );
  }
};

import { CreateManufacturingOrderItem } from "api/external-manufacturing/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useExternalManufacturingOrdersColumns } from "./useExternalManufacturingOrdersColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import styles from "../OrderList.module.css";
import { cx } from "utilities";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { Formik, FormikHelpers, useFormikContext } from "formik";

interface Props {
  close: () => void;
  state: {
    manufacturingItems: CreateManufacturingOrderItem[];
  };
}

export type FormValues = (CreateManufacturingOrderItem & { isSelected: boolean })[];

export const CreateExternalManufacturingOrderModal = ({ close, state }: Props) => {
  const postCreateManufacturingOrderFromItems = externalManufacturingActions.useCreateManufacturingOrderFromItems(
    close,
  );

  const initialValues: FormValues = state.manufacturingItems.map(manufacturingItem => ({
    ...manufacturingItem,
    isSelected: false,
  }));

  const handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const manufacturingOrderItemsDTO: CreateManufacturingOrderItem[] = values
      .filter(
        manufacturingOrderItem =>
          manufacturingOrderItem.isSelected &&
          !manufacturingOrderItem.details.addedToManufacturingOrder,
      )
      .map(({ isSelected, ...restOfManufacturingOrderItem }) => restOfManufacturingOrderItem);

    postCreateManufacturingOrderFromItems.mutate(manufacturingOrderItemsDTO, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  const columns = useExternalManufacturingOrdersColumns();

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Tworzenie zlecenia do producenta
        </Typography>
      }
      width={1400}
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, isValid, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div
              className={cx(
                styles.createManufacturingModalBody,
                "p-3 d-flex flex-column flex-1 overflow-hidden",
              )}
            >
              <Table<FormValues[number]>
                rows={values}
                columns={columns}
                isLoading={false}
                error={null}
                uiSchema={{
                  cell: { height: "30" },
                  header: { backgroundColor: "neutralWhite100" },
                }}
                overrides={() => {
                  return {
                    row: row => {
                      if (row.details.addedToManufacturingOrder) {
                        return {
                          className: styles.createManufacturingOrderOpacity,
                        };
                      }
                      return {};
                    },
                  };
                }}
              />
              <div className="d-flex align-items-center gap-2 pt-4">
                <Button
                  className="text-uppercase"
                  onClick={close}
                  size="medium"
                  variant="transparent"
                >
                  Anuluj
                </Button>
                <Button
                  className="text-uppercase"
                  disabled={
                    !Boolean(
                      values.filter(
                        manufacturingOrderItem =>
                          manufacturingOrderItem.isSelected &&
                          !manufacturingOrderItem.details.addedToManufacturingOrder,
                      ).length,
                    )
                  }
                  isLoading={isSubmitting}
                  size="medium"
                  type="submit"
                  variant="deepPurple"
                >
                  Utwórz zlecenie
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export const useManufacturingOrderItemsSelectionLogic = () => {
  const context = useFormikContext<FormValues>();

  const areAllItemsPreviouslyAdded =
    context.values.filter(
      manufacturingOrderItem =>
        manufacturingOrderItem.isSelected &&
        manufacturingOrderItem.details.addedToManufacturingOrder,
    ).length === context.values.length;

  const areAllItemsSelected =
    context.values.filter(
      manufacturingOrderItem => !manufacturingOrderItem.details.addedToManufacturingOrder,
    ).length ===
      context.values.filter(
        manufacturingOrderItem =>
          manufacturingOrderItem.isSelected &&
          !manufacturingOrderItem.details.addedToManufacturingOrder,
      ).length &&
    context.values.filter(
      manufacturingOrderItem => !manufacturingOrderItem.details.addedToManufacturingOrder,
    ).length !== 0;

  const areSomeItemsSelected =
    context.values.filter(
      manufacturingOrderItem =>
        manufacturingOrderItem.isSelected &&
        !manufacturingOrderItem.details.addedToManufacturingOrder,
    ).length > 0 &&
    context.values.filter(
      manufacturingOrderItem =>
        manufacturingOrderItem.isSelected &&
        !manufacturingOrderItem.details.addedToManufacturingOrder,
    ).length !==
      context.values.filter(
        manufacturingOrderItem => !manufacturingOrderItem.details.addedToManufacturingOrder,
      ).length;

  const setAllItemsSelection = (): void => {
    if (
      context.values.filter(
        manufacturingOrderItem => !manufacturingOrderItem.details.addedToManufacturingOrder,
      ).length !== 0
    ) {
      context.setValues(
        context.values.map(manufacturingOrderItem => ({
          ...manufacturingOrderItem,
          isSelected: !areAllItemsSelected,
        })),
      );
    }
  };

  return {
    areAllItemsPreviouslyAdded,
    areAllItemsSelected,
    areSomeItemsSelected,
    setAllItemsSelection,
  };
};
